import React from 'react';
import { Pie, PieChart, Cell } from 'recharts';
import AppContext from '../../components/App/app_context';
import ScaleHelper from '../../config/scale';

type StructurePieChartProps = {
  structure: any;
  size: { width: number; height: number };
};

class StructurePieChart extends React.Component<StructurePieChartProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { size, structure } = this.props;
    const { colors } = this.context;
    let nothing = 0;

    const pieChartSize = ScaleHelper.scaleFromPx(60, size);

    if (structure.coolingNeedMW + structure.heatingNeedMW === 0) {
      // we use this variable to have a color present even when everything else is zero
      nothing = 1;
    }

    return (
      <PieChart width={pieChartSize} height={pieChartSize}>
        <Pie
          dataKey="value"
          isAnimationActive={true}
          data={[
            { name: 'heat', value: structure.heatingNeedMW },
            { name: 'cold', value: structure.coolingNeedMW },
            { name: 'nothing', value: nothing }
          ]}
          fill="#ff0000"
          innerRadius={ScaleHelper.scaleFromPx(14, size)}
          outerRadius={ScaleHelper.scaleFromPx(20, size)}
          stroke="0"
        >
          <Cell fill={colors.warm} />
          <Cell fill={colors.cold} />
          <Cell fill={colors.lightGrey} />
        </Pie>
      </PieChart>
    );
  }
}

StructurePieChart.contextType = AppContext;

export default StructurePieChart;
