import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'ecto-common/lib/Layout/Layout';
import React from 'react';
import ReactDOM from 'react-dom';
import MultiApp from './js/components/App/MultiApp';
import './js/assets/fonts/font-eon_brix_sans.css';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';
import { setApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { emptyEnvironment } from 'ecto-common/lib/API/ApiConfigType';

// Import and inject moment timezone into moment object.
_.noop(moment);
_.noop(momentTimezone);

darkModeStore.setEnabled(true);
// TODO: Move to release pipeline

setApiEnvironment(
  _.merge({}, emptyEnvironment, {
    name: 'Ectotable',
    urls: {
      ...emptyEnvironment.urls,
      ectotableClientUrl: 'https://ectotableexperiment.azurewebsites.net/api'
    }
  })
);

ReactDOM.render(<MultiApp />, document.getElementById('root'));
