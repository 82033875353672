/**
 * The main file for everything that is rendered on the Table/Grid canvas.
 */

/* eslint no-lone-blocks: 0 */ // --> OFF
import Util from './util';

import Renderable from './renderable';
import Background from './background';
import MessageBox from './message_box';
import ImageButton from './image_button';
import Grid, { EnergyGridSummary } from './energy_grid';
import CountryButtons, { COUNTRY_BUTTON_STATE } from './CountryButtons';
import Structures from './Structures';
import DataTransmitter from './data_transmitter';
import Settings from './Settings';
import TouchTriangleManager from './touch_triangle_manager';
import p5 from 'p5';
import Structure from './Structure';

import BuildingTemplates from './building_templates';

import logo from '../assets/logo.svg';
import logoWithTagline from '../assets/logo_with_tag.svg';
import poweredByPath from '../assets/tagline/powered_by.svg';
import eonLogoOverlayPath from '../assets/tagline/eon.png';
import addIcon from '../assets/button_drawer/add.svg';

import STATES from '../config/states';
import ButtonDrawer from './button_drawer/button_drawer';
import {
  comboViewMessageTypes,
  comboRoleScreenRequestMessage
} from '../assets/combo_view';
import { MultiViewTypes, getRoomToken } from '../components/App/app_util';
import MenuDrawer, { MenuDrawerButton } from './button_drawer/menu_drawer';
import { preLoadedBuildings } from 'js/Table/building_templates';
import {
  compose,
  identity,
  translate,
  scale,
  applyToPoint,
  inverse
} from 'transformation-matrix';
import ButtonManager from 'js/Table/button_drawer/button_manager';
import buildingIcons from '../config/buildingIcons';
import _ from 'lodash';
import NumberBuffer from 'js/utils/NumberBuffer';
import SocketMessages from '../../../server/socket-messages';
import TABLESTATES from '../config/states';
import { TableContextType } from 'js/Table/context';
import { TableLocation } from 'js/assets/default_location';
import { IntegrationFontDefinitions } from 'js/Table/Integration';
import { RoomConfigType } from 'js/Table/types';
import TableP5 from 'js/Table/TableP5';
import AccumulatorTank from 'js/Table/AccumulatorTank';
import { setDataManagerLocationData } from 'js/Table/data/data_manager';
import EctogridBuildingDefinitions from 'js/Table/EctogridBuildingDefinitions';
import EctogridCluster from 'js/Table/EctogridCluster';
import AccTankArrow from '../assets/acc_tank_arrow.svg';
import removeIcon from '../assets/remove.svg';

const MIN_DIST_FOR_PAN_OR_DRAG_SQ = 4;

// How long time does it take from last interaction until we consider the table idle
const TIME_FROM_INTERACTION_TO_IDLE = 30 * 60;
let lastInteraction = performance.now();
const carbonOnline = true;

function _arrayToIndexedOnIdentifier(touchArray) {
  const touchesObject = {};

  for (let i = touchArray.length - 1; i >= 0; i--) {
    const touch = touchArray[i];

    touchesObject[touch.identifier] = touch;
  }

  return touchesObject;
}

function _indexedToArray(object) {
  return Object.values(object);
}

function clearTUIOState(node) {
  node.validTUIOObject = false;
}

/**
 * The main object for the table renderable
 * @class App
 */
class TableApp extends Renderable {
  dataTransmitter: DataTransmitter;
  room: string;
  roomStorageKey: string;
  roomConfig: RoomConfigType;
  usingTUIO: boolean;
  debugMessage: string;
  buttonDrawer: ButtonDrawer;
  currentlyDragging: any;
  panning: boolean;
  pinchZooming: boolean;
  panStartPos: { x: number; y: number };
  p5Instance: TableP5;
  socket: any;
  structuresToClear: any[];
  dragOffsetX: number;
  dragOffsetY: number;
  countryButtons: CountryButtons;
  frameTimeBuffer: NumberBuffer;
  inEctocloudMode: boolean;
  structures: Structures;
  touchTriangleManager: TouchTriangleManager;
  buildingMenuDrawer: MenuDrawer;
  lastMovedPos: { x: number; y: number };
  buttons: ButtonManager;
  touchEndReciever: any;
  background: Background;
  logoButton: ImageButton;
  addButton: ImageButton;
  transform: any;
  state: any;
  trayPointingBox: MessageBox;
  poweredByImage: p5.Image;
  eonLogoOverlayImage: p5.Image;
  logoButtonDefault: any;
  showingSetup: boolean;
  tuioHandlesTouchEvents: boolean;
  setupMessage: string;
  subState: any;
  rotation: boolean;
  startScreenTimeout: number;
  location: TableLocation;
  fonts: IntegrationFontDefinitions;
  evaluateNumberOfTouchesTimer: number;
  deferredActions: (() => void)[];
  lastDraggedTimestamp: number;
  grid: Grid;
  gridSummary: EnergyGridSummary;
  loadingFormData: boolean;
  imageCache: Record<string, p5.Image>;

  constructor(
    context: TableContextType,
    p5Instance: TableP5,
    socket,
    room,
    roomConfig,
    openSettings: () => void
  ) {
    super(context);
    this.imageCache = {};

    this.imageCache[AccTankArrow] = p5Instance.loadImage(AccTankArrow);
    this.imageCache[removeIcon] = p5Instance.loadImage(removeIcon);

    for (let def of Object.values(EctogridBuildingDefinitions)) {
      console.log('Here, calling load image');
      this.imageCache[def.image] = p5Instance.loadImage(def.image);
    }

    this.tuioHandlesTouchEvents = false;
    this.lastDraggedTimestamp = 0;
    this.grid = new Grid(true);
    this.deferredActions = [];
    this.room = room;
    this.roomConfig = roomConfig;
    this.dataTransmitter = new DataTransmitter(context, socket);
    this.usingTUIO = false;

    if (socket) {
      socket.isTable = true;
    }
    this.p5Instance = p5Instance;
    p5Instance.App = this;

    // The 1:1 configuration still needs the combo button
    const enableComboButton =
      this.roomConfig?.multiScreen == null ||
      this.roomConfig?.multiScreen == MultiViewTypes.MULTI_1_1;

    this.buttonDrawer = new ButtonDrawer(
      this,
      this.roomConfig?.multiScreen != null,
      enableComboButton
    );
    this.setCommunication(socket);

    this.setState(STATES.UNKNOWN);
    this.pinchZooming = false;
    this.structuresToClear = [];

    this.socket = socket;

    this.panStartPos = { x: 0, y: 0 };
    this.panning = false;

    // this.transform = scale(this.p5.scaleRatio);
    this.transform = identity();

    this.loadingFormData = false;
    this.dragOffsetX = 0;
    this.dragOffsetY = 0;
    this.countryButtons = new CountryButtons(context);
    this.countryButtons.addListener(this);

    this.frameTimeBuffer = new NumberBuffer(30);
    this.inEctocloudMode = false;
    this.structures = new Structures(context);

    this.touchTriangleManager = new TouchTriangleManager(context, p5Instance);
    this.touchTriangleManager.addListener(this);

    this.buildingMenuDrawer = new MenuDrawer();
    this.buildingMenuDrawer.options = _.orderBy(preLoadedBuildings, 'name')
      .filter((building) => building.buildingId !== 'accumulatortank')
      .map(
        (building) =>
          new MenuDrawerButton(
            building.buildingId,
            building.name,
            () => {
              this.addBuilding(building);
            },
            buildingIcons.buildingIcons[building.icon]
          )
      );

    this.buildingMenuDrawer.renderInit(p5Instance, this.imageCache);
    p5Instance.carbon = 10;

    this.lastMovedPos = { x: undefined, y: undefined };

    this.touchEndReciever = {};

    this.background = new Background();

    this.logoButton = new ImageButton(logoWithTagline, logo);
    this.logoButton.setDefault({
      x: 40,
      y: 40,
      width: 145,
      height: 50
    });

    this.addButton = new ImageButton(addIcon, addIcon);
    this.addButton.setDefault({
      x: 0,
      y: 0,
      width: 40,
      height: 40
    });

    this.addButton.isActiveCallback = () => {
      return this.buildingMenuDrawer.visible;
    };

    this.addButton.addListener(() => {
      this.buildingMenuDrawer.toggleVisibility();
    });

    this.logoButton.addListener(() => {
      this.structures.clear();
    });

    this.trayPointingBox = new MessageBox(
      'Add Buildings using the add button or from the tray',
      'bottom'
    );

    this.buttons = new ButtonManager([this.logoButton, this.addButton]);

    this.poweredByImage = p5Instance.loadImage(poweredByPath);
    this.eonLogoOverlayImage = p5Instance.loadImage(eonLogoOverlayPath);

    this._removeTouchStructure = this._removeTouchStructure.bind(this);

    this.showingSetup = false;
    this.countryButtons.setState(COUNTRY_BUTTON_STATE.CONTRACTED);
    this.countryClicked({ place: Settings.places[0] });
    this.openSettings = openSettings;
    this.setState(STATES.ACTIVE);
    this.structures.layout(p5);

    // setTimeout(() => {
    //   this.addBuilding(building_templates.preLoadedBuildings.commercial);
    //   setTimeout(() => {
    //     this.addBuilding(building_templates.preLoadedBuildings.datacenter);
    //     setTimeout(() => {
    //       setTimeout(() => {
    //         this.addBuilding(building_templates.preLoadedBuildings.pvcell);
    //       }, 200);
    //     }, 200);
    //   }, 200);
    // }, 200);
  }

  showingFullscreenInfoView() {
    return (
      this.roomConfig?.multiScreen != null &&
      this.state === STATES.INFO &&
      this.buttonDrawer.isScreenContentFullscreen(this.subState)
    );
  }

  scheduleStructureDeletion = (node) => {
    if (!node.validTUIOObject) {
      this.structuresToClear.push(node);
    }
  };

  // This function is called very frequently so I've designed it to avoid allocations as much as possible.
  updateTUIOPoints(points, markerDiameter) {
    this.usingTUIO = true;
    this.structures.forEach(clearTUIOState);
    let modifiedStructures = true;

    const boundingRect =
      this.p5Instance.drawingContext.canvas.getBoundingClientRect();

    for (let point of points) {
      const x = (point.x - boundingRect.x) / this.p5Instance.scaleRatio;
      const y = (point.y - boundingRect.y) / this.p5Instance.scaleRatio;
      const classId = point.classId % BuildingTemplates.numTouchFingerPrints;

      const existingStructure = this.structures.findByTUIOID(classId);
      if (existingStructure != null) {
        // Update existing structure etc.
        if (
          existingStructure.centerPos.x !== x ||
          existingStructure.centerPos.y !== y
        ) {
          existingStructure.touchTriangleCenterUpdated(
            this.p5Instance.createVector(x, y)
          );
        }

        if (existingStructure.reportedRotation !== point.angle) {
          existingStructure.touchTriangleRotationUpdated?.(point.angle);
        }

        existingStructure.validTUIOObject = true;
      } else {
        let buildingTemplate =
          BuildingTemplates.findPreLoadedBuildingByTuioId(classId);

        if (buildingTemplate != null) {
          this.setState(STATES.ACTIVE);

          let newObject = new buildingTemplate.class(
            this.context,
            this.p5Instance,
            buildingTemplate,
            markerDiameter /
              (Settings.baseStructureSize + Settings.structureDialSize) /
              this.p5Instance.scaleRatio,
            this.location.city
          );

          newObject.touchTriangleCenterUpdated(
            this.p5Instance.createVector(x, y)
          );
          newObject.touchTriangleRotationUpdated?.(point.angle);

          this.structures.push(newObject);
          clearTimeout(this.startScreenTimeout);
          modifiedStructures = true;
        }
      }
    }

    this.structures.forEach(this.scheduleStructureDeletion);
    for (let structureToRemove of this.structuresToClear) {
      this._removeTouchStructure(structureToRemove);
      modifiedStructures = true;
    }

    if (modifiedStructures) {
      this.structures.pipeAllStructures();
    }

    this.structuresToClear.length = 0;
  }

  get hasStructures() {
    return this.structures.length() > 0;
  }

  setCommunication(socket) {
    this.dataTransmitter = new DataTransmitter(this.context, socket);

    socket.on(SocketMessages.TABLE_STATE, (tableStateObject) => {
      const { mainState, subState, fromTableApp } = tableStateObject;
      socket.emit(SocketMessages.REQUEST_ECTOCLOUD_MODE);
      socket.emit(...comboRoleScreenRequestMessage());

      if (!fromTableApp) {
        this.setState(TABLESTATES[mainState], subState);
      }
    });

    socket.on(SocketMessages.SET_ECTOCLOUD_MODE, (ectocloudModeEnabled) => {
      this.inEctocloudMode = ectocloudModeEnabled;
    });

    socket.on(comboViewMessageTypes.CHANGE, ({ comboScreen }) => {
      // console.info(`[${ comboViewMessageTypes.CHANGE }] Current: ${ comboScreen }`);
      this.buttonDrawer.currentComboScreen = comboScreen;
      this.buttonDrawer.showComboViewToggle(this);
    });

    socket.on(comboViewMessageTypes.DISCONNECT, () => {
      console.info(`[${comboViewMessageTypes.DISCONNECT}]`);
      if (this.roomConfig?.multiScreen == null) {
        this.buttonDrawer.hideComboViewToggle(this);
      }
    });
  }

  socketConnected() {
    this.structures.touch();
  }

  /**
   * @param {object} state one of the states in the STATES selection
   * @param {any} subState more state information, avaible value related to the main state
   * @throws {String} Message about bad in parameters
   * @returns {RESULTS} Success or Failure code
   */
  setState(state, subState = null) {
    if (Object.values(STATES).indexOf(state) === -1) {
      throw 'App.setState got called with bad value';
    }

    if (this.state === state && this.subState === subState) {
      return Util.NOOP;
    }

    this.dataTransmitter.setTableState(state, subState);
    this.subState = subState;
    this.state = state;

    return Util.SUCCESS;
  }

  hasBeenIdleForLong() {
    const secondsWithoutInteraction = Math.floor(
      (performance.now() - lastInteraction) / 1000
    );

    console.info(
      secondsWithoutInteraction.toString() +
        ' seconds since last interaction, ' +
        (TIME_FROM_INTERACTION_TO_IDLE - secondsWithoutInteraction).toString() +
        ' seconds until refresh'
    );

    return secondsWithoutInteraction > TIME_FROM_INTERACTION_TO_IDLE;
  }

  registerInteraction() {
    lastInteraction = performance.now();
  }

  renderInit(p5Instance: TableP5, fonts, model) {
    if (this.state === STATES.UNKNOWN) {
      setTimeout(() => this.renderInit(p5Instance, fonts, model), 15);
    }

    this.context.model = model;
    this.countryButtons.renderInit(p5Instance, fonts);
    console.log(this.background);
    this.background.renderInit(p5Instance);

    this.buttons.renderInit(p5Instance, this.imageCache);
    this.fonts = fonts;
  }

  async getFormDataFromServer(place) {
    this.loadingFormData = true;
    try {
      const buildingDefinitions =
        EctogridBuildingDefinitions[place.city] ??
        EctogridBuildingDefinitions.Lund;

      const response = await fetch(
        `${window.location.protocol}//${window.location.host}/${buildingDefinitions.formFile}`
      );

      if (!response.ok) {
        throw new Error(response.status.toString());
      }
      const json = await response.json();

      this.loadingFormData = false;
      return json;
    } catch (error) {
      this.loadingFormData = false;
      console.warn(error);
    }
    return null;
  }

  async getCarbonFromServer(place) {
    try {
      const response = await fetch(
        `${window.location.protocol}//${window.location.host}/carbon?countryCode=${place.countryCode}`
      );

      if (!response.ok) {
        throw new Error(response.status.toString());
      }
      const json = await response.json();

      return json.carbonIntensity;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async setLocation(place) {
    this.location = place;
    this.countryButtons.setLocation(place);

    if (carbonOnline) {
      this.p5Instance.carbon =
        (await this.getCarbonFromServer(place)) || place.fallbackConsumption;
    }

    const formData = await this.getFormDataFromServer(place);
    setDataManagerLocationData(formData);
    this.structures.touch();
    // this.addBuilding(BuildingTemplates.preLoadedBuildings.ectogrid);
  }

  countryClicked(countryButton) {
    if (countryButton !== undefined) {
      this.setState(STATES.ACTIVE);
      this.setLocation(countryButton.place);
    }
  }

  toggleEctocloudMode() {
    this.inEctocloudMode = !this.inEctocloudMode;
    this.dataTransmitter.setEctocloudMode(this.inEctocloudMode);
  }

  initiateFullRefresh() {
    this.dataTransmitter.refreshAll();
    window.location.reload();
  }

  ectomodeButtonEnabled() {
    return getRoomToken() != null;
  }

  openSettings() {
    console.info('Trying to open settings view.');
  }

  mousePressed(x, y, source: 'touch' | 'mouse' | 'tuio' = 'mouse') {
    const [transformedX, transformedY] = this.transformedPoint(x, y);

    if (source === 'tuio') {
      this.tuioHandlesTouchEvents = true;
    } else if (this.tuioHandlesTouchEvents) {
      return;
    }

    if (source === 'mouse') {
      this.registerInteraction();
    }

    this.panStartPos.x = x;
    this.panStartPos.y = y;
    this.lastMovedPos.x = x;
    this.lastMovedPos.y = y;

    let result;

    if (this.structures.mousePressed(transformedX, transformedY)) {
      return;
    }

    if (this.buildingMenuDrawer?.mousePressed(x, y)) {
      return;
    }

    if (this.buttons.mousePressed(x, y)) {
      return;
    }

    if (this.buttonDrawer.mousePressed(x, y)) return;

    switch (this.state) {
      case STATES.ACTIVE:
        {
          result = this.countryButtons.mousePressed(x, y);

          // If the mouse down was not on any of the buttons
          if (!result && !this.shouldDisableDynamicTouchUI()) {
            const structureGotHit = this.structures.hitAStructure(
              transformedX,
              transformedY
            );

            if (structureGotHit) {
              const [structure, hitX, hitY] = structureGotHit;
              this.dragOffsetX = hitX;
              this.dragOffsetY = hitY;
              this.currentlyDragging = structure;
              this.structures.forEach(this.clearActiveStructure);
              structure.active = true;
            } else {
              this.currentlyDragging = null;
            }
          }
        }
        break;
      default:
    }
  }

  transformedPoint = (x, y) => {
    return applyToPoint(inverse(this.transform), [x, y]);
  };

  validateAccumulatorTank() {
    // Disable adding acc tank due to customer feedback
    // let accumulatorTank: PhysicalNode = this.structures.find(struct => struct.buildingId === 'accumulatortank');
    // const allLeaves = this.structures.getAllLeaves();
    // const hasAccumulatorTank = accumulatorTank != null;
    // if (!hasAccumulatorTank && allLeaves.length >= 2) {
    //   // Add accumulator tank.. somewhere
    //   this.deferredActions.push(() => {
    //     const accBuildingTemplate = building_templates.preLoadedBuildings.accumulatortank;
    //     this.addBuilding(accBuildingTemplate);
    //   })
    //   // setTimeout(() => {
    //   //   const accBuildingTemplate = building_templates.preLoadedBuildings.accumulatortank;
    //   //   this.addBuilding(accBuildingTemplate);
    //   // }, 100);
    // } else if (hasAccumulatorTank && allLeaves.length < 2) {
    //   // Remove accumulator tank
    //   this.structures.remove(accumulatorTank);
    // }
  }

  addBuilding(buildingTemplate) {
    const p5Instance = this.p5Instance;

    let newObject;

    newObject = new buildingTemplate.class(
      this.context,
      p5Instance,
      buildingTemplate,
      1,
      this.location.city,
      this.imageCache
    );

    let positions = [
      [p5Instance.renderWidth * 0.25, p5Instance.renderHeight * 0.25],
      [p5Instance.renderWidth * 0.75, p5Instance.renderHeight * 0.25],
      [p5Instance.renderWidth * 0.25, p5Instance.renderHeight * 0.75],
      [p5Instance.renderWidth * 0.75, p5Instance.renderHeight * 0.75]
    ];

    let [x, y] = positions[this.structures._nodes.length % positions.length];

    let [transformedX, transformedY] = this.transformedPoint(x, y);

    newObject.touchTriangleCenterUpdated(
      p5Instance.createVector(transformedX, transformedY)
    );
    newObject.layout(p5Instance);

    const newObjectRadius = (newObject.shapeRadius?.() ?? 0) + 40;

    let structure = this.structures.hitAStructure(
      transformedX,
      transformedY,
      newObjectRadius
    );

    while (structure != null) {
      x += 20;
      y += 20;
      [transformedX, transformedY] = this.transformedPoint(x, y);
      structure = this.structures.hitAStructure(
        transformedX,
        transformedY,
        newObjectRadius
      );
    }

    const singlePoint = p5Instance.createVector(transformedX, transformedY);
    newObject.touchTriangleCenterUpdated(singlePoint);
    this.structures.push(newObject);
    clearTimeout(this.startScreenTimeout);

    this.structures.pipeAllStructures();

    this.buildingMenuDrawer.visible = false;
    this.validateAccumulatorTank();
  }

  mouseReleased(x, y, source: 'touch' | 'mouse' | 'tuio' = 'mouse') {
    if (source !== 'tuio' && this.tuioHandlesTouchEvents) {
      return;
    }

    this.lastDraggedTimestamp = 0;
    let inTableState = this.state === STATES.ACTIVE;
    this.buttons.mouseReleased(x, y);

    const [transformedX, transformedY] = this.transformedPoint(x, y);
    const structuresHandledEvent = this.structures.mouseReleased(
      transformedX,
      transformedY
    );
    this.buttonDrawer.mouseReleased(x, y);
    this.buildingMenuDrawer?.mouseReleased(x, y);

    inTableState &&
      !structuresHandledEvent &&
      this.currentlyDragging == null &&
      this.countryButtons.mouseReleased(x, y);

    if (!this.currentlyDragging && !structuresHandledEvent) {
      this.structures.forEach(this.clearActiveStructure);
    }

    this.panning = false;

    if (source === 'mouse') {
      this.registerInteraction();
    }

    this.currentlyDragging = undefined;
  }

  shouldDisableDynamicTouchUI() {
    return this.touchTriangleManager.hasTouchTriangles() || this.usingTUIO;
  }

  mouseDragged(x, y, source: 'touch' | 'mouse' | 'tuio' = 'mouse') {
    if (source !== 'tuio' && this.tuioHandlesTouchEvents) {
      return;
    }

    this.lastDraggedTimestamp = performance.now();
    if (x < 0) {
      return;
    }

    const [transformedX, transformedY] = this.transformedPoint(x, y);
    let inTableState = this.state === STATES.ACTIVE;

    this.debugMessage = '1';
    if (this.buttons.mouseDragged(x, y)) {
      return;
    }

    this.debugMessage = '2';
    if (this.structures.mouseDragged(transformedX, transformedY)) {
      return;
    }

    this.debugMessage = '3';
    if (this.buttonDrawer.mouseDragged(x, y)) {
      return;
    }

    this.debugMessage = '4';
    if (inTableState && this.countryButtons.mouseDragged(x, y)) {
      return;
    }

    this.debugMessage = '5';
    if (source === 'mouse') {
      this.registerInteraction();
    }

    this.debugMessage = '6';
    if (this.shouldDisableDynamicTouchUI()) {
      // Disable panning & zooming when using touch triangles
      return;
    }

    this.debugMessage = '7';
    let dxStartX = x - this.panStartPos.x;
    let dxStartY = y - this.panStartPos.y;
    let distStartSq = Math.sqrt(dxStartX * dxStartX + dxStartY * dxStartY);

    this.debugMessage = '8 ' + this.currentlyDragging;
    if (this.currentlyDragging != null) {
      this.debugMessage = '9a';
      if (this.rotation) {
        const xDelta = x - this.lastMovedPos.x;
        const yDelta = y - this.lastMovedPos.y;

        this.currentlyDragging.touchTriangleRotationUpdated(
          this.currentlyDragging.getRotationAngle() + (xDelta + yDelta) / 100
        );
      } else {
        this.currentlyDragging.touchTriangleCenterUpdated(
          this.p5Instance.createVector(
            transformedX + this.dragOffsetX,
            transformedY + this.dragOffsetY
          )
        );
        this.structures.pipeAllStructures();
      }
    } else if (this.panning && !this.pinchZooming) {
      this.debugMessage = '9';
      let dxLastX = x - this.lastMovedPos.x;
      let dxLastY = y - this.lastMovedPos.y;

      this.transform = compose(translate(dxLastX, dxLastY), this.transform);
    } else if (
      !this.panning &&
      distStartSq > MIN_DIST_FOR_PAN_OR_DRAG_SQ &&
      !this.pinchZooming
    ) {
      this.debugMessage = '9b';
      this.panning = true;
      let dxLastX = x - this.lastMovedPos.x;
      let dxLastY = y - this.lastMovedPos.y;

      this.transform = compose(translate(dxLastX, dxLastY), this.transform);
    } else {
      this.debugMessage =
        '9c' +
        '-' +
        this.panning +
        '-' +
        this.buildingMenuDrawer.visible +
        '-' +
        distStartSq +
        '-' +
        this.pinchZooming;
    }

    this.lastMovedPos.x = x;
    this.lastMovedPos.y = y;
  }

  touchMarkersDisabled() {
    return this.roomConfig?.multiScreen != null;
  }

  clearActiveStructure = (structure) => {
    structure.active = false;
  };

  touchStarted(ev) {
    if (this.tuioHandlesTouchEvents) {
      return;
    }

    this.registerInteraction();
    const allTouches = _arrayToIndexedOnIdentifier(ev.touches);

    for (let i = 0; i < ev.changedTouches.length; i++) {
      const touch = ev.changedTouches[i];

      // First check if this touch was just removed from a triangle and should be seen as flicker
      if (this.touchTriangleManager.newTouchWasFlicker(touch)) {
        this.touchTriangleManager.clearFlickerRemovalForTouch(touch);
        continue;
      }

      // try to detect turning on debug mode by tapping in the left top corner
      if (touch.pageX < 40 && touch.pageY < 40) {
        this.context.debug = !this.context.debug;
        delete allTouches[touch.identifier];
      }
    }

    this.touchTriangleManager.touchStarted(_indexedToArray(allTouches));
    this.structures.pipeAllStructures();

    this.mousePressed(ev.pageX, ev.pageY, 'touch');
  }

  touchEnded(ev) {
    this.registerInteraction();
    const changedTouches = _arrayToIndexedOnIdentifier(ev.changedTouches);
    const structures = this.structures;

    this.touchTriangleManager.touchEnded(
      _indexedToArray(changedTouches),
      () => {
        structures.pipeAllStructures.bind(structures);
        this.structures.pipeAllStructures();
      }
    );

    this.mouseReleased(ev.pageX, ev.pageY, 'touch');
  }

  touchMoved(ev) {
    if (this.tuioHandlesTouchEvents) {
      return;
    }

    this.registerInteraction();

    const changedTouches = _arrayToIndexedOnIdentifier(ev.changedTouches);
    this.touchTriangleManager.updateTouchPoints(
      _indexedToArray(changedTouches)
    );
    this.structures.pipeAllStructures();

    this.mouseDragged(ev.pageX, ev.pageY, 'touch');
  }

  touchTriangleAdded(newTouchTriangle) {
    if (this.tuioHandlesTouchEvents) {
      return;
    }

    let buildingTemplate = BuildingTemplates.findPreLoadedBuilding(
      newTouchTriangle.fingerPrint
    );

    // Disable zooming and panning when using touch markers.
    this.transform = identity();
    this.pinchZooming = false;

    // Each building have a reference circumference -
    // this setting is arbitrary and just relevant in relation to the other buildings
    // and with the number below used to calculate the size of the drawn circles
    const arbitraryCircumferenceToScaleRatio = 0.7;
    const circumferenceScaling =
      newTouchTriangle.circumference / buildingTemplate.baseCircumference;

    let structureScale =
      circumferenceScaling * arbitraryCircumferenceToScaleRatio;

    this.setState(STATES.ACTIVE);

    let newObject = new buildingTemplate.class(
      this.context,
      this.p5Instance,
      buildingTemplate,
      structureScale,
      this.location.city
    );

    newTouchTriangle.addListener(newObject);
    this.structures.push(newObject);
    clearTimeout(this.startScreenTimeout);
  }

  _removeTouchStructure(touchStructure) {
    this.structures.remove(touchStructure);
    this.validateAccumulatorTank();
    this.structures.pipeAllStructures();
  }

  touchTriangleRemoved(touchTriangleToBeRemoved) {
    const listeners = touchTriangleToBeRemoved.listeners.slice();

    listeners.forEach(this._removeTouchStructure);
  }

  keyPressed(p5Instance: TableP5) {
    // turn on debugging on desktop when pressing 'd'
    if (p5Instance.key === 'd') this.context.debug = !this.context.debug;

    if (p5Instance.key === 'r') this.rotation = !this.rotation;

    if (p5Instance.key === 'l') {
      const grid = this.structures._nodes[0] as EctogridCluster;

      const reload = () => {
        grid.recombineData();
        this.structures.touch();
      };
      if (grid == null) {
        this.addBuilding(BuildingTemplates.preLoadedBuildings.ectogrid);
        return;
      }

      this.structures.pipeAllStructures();
      this.validateAccumulatorTank();
      reload();
    }
  }

  zoomScale() {
    const { a: zoomScale } = this.transform;

    return zoomScale;
  }

  zoom(zoomScale, x, y) {
    const oldScaleFactor = this.zoomScale();
    const deltaScale = zoomScale / oldScaleFactor;
    this.transform = compose(
      translate(x, y),
      scale(deltaScale, deltaScale),
      translate(-x, -y),
      this.transform
    );
  }

  mouseWheel(delta, x, y) {
    let newScale = this.zoomScale() * 1.1;

    if (delta < 0) {
      newScale = this.zoomScale() * 0.9;
    }

    this.zoom(newScale, x, y);
  }

  keyReleased(_p5Instance: TableP5) {}

  draw(p5Instance: TableP5) {
    const p0 = performance.now();
    p5Instance.textFont(this.fonts.medium);

    p5Instance.push();
    this.background.draw(p5Instance, this.transform);
    p5Instance.pop();

    if (this.context.debug) {
      p5Instance.printDebugStuff();
    }

    // If we are in the process of timeout because we haven't seen any touches
    if (this.evaluateNumberOfTouchesTimer) {
      // cancel that timeout if indeed there are touches
      if (p5Instance.touches.length > 0) {
        clearTimeout(this.evaluateNumberOfTouchesTimer);
        this.evaluateNumberOfTouchesTimer = undefined;
      }
    }

    this.buildingMenuDrawer.layout(p5Instance);

    this.addButton.defaultRect.x =
      p5Instance.renderWidth -
      this.buildingMenuDrawer.menuLayout.menuWidth / 2 -
      30;
    this.addButton.defaultRect.y = p5Instance.renderHeight - 70;

    if (this.hasStructures) {
      const startNode = this.structures.getFirstLeaf();

      if (startNode !== undefined && startNode instanceof Structure) {
        this.grid.reset();
        const energyNode = startNode.getEnergyNode();
        const summary = this.grid.summarise(energyNode);
        const ectogrid = this.structures.find(
          (x) => x.buildingId === 'ectogrid'
        );
        if (ectogrid) {
          const ectogridImpl = ectogrid as EctogridCluster;
          ectogridImpl.setSummary(summary);
        }

        const accTank = this.structures.find(
          (x) => x.buildingId === 'accumulatortank'
        );

        if (accTank) {
          const accTankImpl = accTank as AccumulatorTank;
          accTankImpl.setSummary(summary);
        }

        this.grid.distribute(summary);
      }
      p5Instance.push();
      const { a, b, c, d, e, f } = this.transform;
      p5Instance.applyMatrix(a, b, c, d, e, f);
      this.structures.draw(p5Instance);
      p5Instance.pop();
    } else if (!this.buildingMenuDrawer.visible && !this.loadingFormData) {
      this.trayPointingBox.draw(
        p5Instance,
        this.addButton.defaultRect.x - 79,
        this.addButton.defaultRect.y - 120
      );
    }

    if (!this.loadingFormData) {
      this.buttons.draw(p5Instance);
    }

    this.buttonDrawer.draw(p5Instance);
    if (this.buildingMenuDrawer.visible) {
      this.buildingMenuDrawer.draw(
        p5Instance,
        this.addButton.defaultRect.x -
          this.buildingMenuDrawer.menuLayout.menuWidth / 2,
        this.addButton.defaultRect.y - this.buildingMenuDrawer.height() - 10
      );
    }

    const delta = performance.now() - this.lastDraggedTimestamp;

    // When the user is actively touch interacting we don't want to send data continuously
    // since new data is likely to appear soon. Otherwise, graph animations would be interrupted
    // etc

    if (delta > 400) {
      // Don't worry that it looks like this is sending every frame, the transmitter object will bundle them
      this.dataTransmitter.transmit(this.grid, this.structures, this.location);
    }

    this.countryButtons.draw(p5Instance);

    this.frameTimeBuffer.push(performance.now() - p0);

    for (let action of this.deferredActions) {
      action();
    }

    this.deferredActions.length = 0;
  }
}

export default TableApp;
