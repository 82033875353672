import React, { Component } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Bar
} from 'recharts';
import Box from '../../components/Box/Box';
import ScaleHelper from '../../config/scale';
import AppContext from '../../components/App/app_context';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { formatNumber } from 'ecto-common/lib/utils/stringUtils';

type EnergyDemandBarChartProps = {
  data: any;
  darkModeEnabled: boolean;
  size: { width: number; height: number };
};

type EnergyDemandBarChartState = {
  chronoligicalChartData: any[];
};

class EnergyDemandBarChart extends Component<
  EnergyDemandBarChartProps,
  EnergyDemandBarChartState
> {
  constructor(props) {
    super(props);
    this.state = {
      chronoligicalChartData: []
    };
  }

  static summarisePerMonth(heatingNeed, coolingNeed) {
    const needPerMonth = [];

    for (let month = 0; month < 12; month++) {
      needPerMonth[month] = {
        heatingNeed: heatingNeed[month],
        coolingNeed: coolingNeed[month],
        month: Highcharts.getOptions().lang.shortMonths[month]
      };
    }
    return needPerMonth;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.heatingNeedPerMonth) {
      const chronoligicalChartData = EnergyDemandBarChart.summarisePerMonth(
        props.data.heatingNeedPerMonth,
        props.data.coolingNeedPerMonth
      );

      // This comparison is really quick and dirty and not optimal (but gets the job done)
      // More https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
      if (
        JSON.stringify(chronoligicalChartData) !==
        JSON.stringify(state.chronoligicalChartData)
      ) {
        return {
          chronoligicalChartData
        };
      }
    }
    return null;
  }

  shouldComponentUpdate(nextProps, _nextState) {
    return !!nextProps.data && this.context.connectionState;
  }

  render() {
    const { colors } = this.context;
    const { chronoligicalChartData } = this.state;
    const { size, darkModeEnabled } = this.props;
    const componentMargin = ScaleHelper.scaleFromPx(20, size);
    const gridStrokeDash = ScaleHelper.scaleFromPx(3, size);

    const fillColor = darkModeEnabled ? colors.lighterGrey : colors.lightGrey;

    return (
      <Box title="Consumption Per Month">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={800}
            height={500}
            data={chronoligicalChartData}
            margin={{
              top: componentMargin,
              right: componentMargin,
              left: componentMargin,
              bottom: 0 // 0 because we don't render label ticks
            }}
          >
            <CartesianGrid
              strokeDasharray={`${gridStrokeDash} ${gridStrokeDash}`}
              horizontal={false}
              stroke={colors.lightGrey}
            />
            <XAxis axisLine={false} dataKey="month" />
            <YAxis
              axisLine={false}
              width={ScaleHelper.scaleFromPx(60, size)}
              label={{
                value: 'kWh',
                angle: -90,
                position: 'insideLeft',
                fill: fillColor,
                fontSize: ScaleHelper.scaleFromPx(18, size)
              }}
              tickFormatter={formatNumber}
              tick={{
                fill: fillColor,
                fontSize: ScaleHelper.scaleFromPx(16, size)
              }}
            />
            <ReferenceLine y={0} stroke={colors.lightGrey} />
            <Bar
              type="monotone"
              dataKey="heatingNeed"
              stroke={colors.warm}
              fill={colors.warm}
            />
            <Bar
              type="monotone"
              dataKey="coolingNeed"
              stroke={colors.cold}
              fill={colors.cold}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }
}

EnergyDemandBarChart.contextType = AppContext;

export default EnergyDemandBarChart;
