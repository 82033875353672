import React, { useState, useEffect, useMemo } from 'react';
import DashboardDataContext, {
  DashboardDataContextType
} from 'ecto-common/lib/hooks/DashboardDataContext';
import API, { cancellablePromiseList } from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import {
  createEquipmentMap,
  createFlatNodeTree,
  createNodeMap
} from 'ecto-common/lib/utils/locationUtils';
import store, { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { Provider } from 'react-redux';

import _ from 'lodash';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import EventHubService, {
  EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
  EventHubServiceContext
} from 'ecto-common/lib/EventHubConnection/EventHubService';
import { getRoomToken } from '../../components/App/app_util';
import './EctocloudDashboardEnvironment.css';
import ClientNr from '../../components/App/ClientNr';
import { setNodes } from 'ecto-common/lib/actions/getNodes';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { setEnums } from 'ecto-common/lib/actions/getEnums';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import {
  NodeResponseModel,
  SignalTypeResponseModel,
  EquipmentTypeResponseModel,
  GetEnumsAndFixedConfigurationsResponseModel
} from 'ecto-common/lib/API/APIGen';
import { GridType } from 'ecto-common/lib/API/EctotableClientAPIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { IPublicClientApplication } from '@azure/msal-browser';

const MEDICON_VILLAGE_CONTEXT_SETTINGS: ApiContextSettings = {
  tenantId: 'se-mediconvillage'
};
const MEDICON_VILLAGE_NODE_ID = '126d1676-cc57-44ec-b7eb-1fafa0bc1393';
const RETRY_TIMEOUT = 10000;

const msalConfiguration = {
  acquireTokenSilent: () => Promise.resolve({ accessToken: getRoomToken() }),
  acquireTokenRedirect: () => {}
};

const getResourcesPromise = () => {
  return cancellablePromiseList([
    API.Nodes.getAllNodes(MEDICON_VILLAGE_CONTEXT_SETTINGS),
    API.Nodes.getGrids(MEDICON_VILLAGE_CONTEXT_SETTINGS),
    API.Enums.getEnums(MEDICON_VILLAGE_CONTEXT_SETTINGS),
    API.SignalTypes.getAllSignalTypes(MEDICON_VILLAGE_CONTEXT_SETTINGS),
    API.Equipments.getEquipmentTypes(MEDICON_VILLAGE_CONTEXT_SETTINGS)
  ] as const);
};

const TimeRangeValue = {
  timeRangeOption: TimeRangeOptions.DAY,
  referenceDate: null,

  // Each consumer of time range adds or removes them self from the time range user

  addTimeRangeConsumer: (_consumer) => {},
  removeTimeRangeConsumer: (_consumer) => {}
};

const EctotableDashboardEnvironment = ({ children }) => {
  const [dashboardResourcesValue, setDashboardResourcesValue] = useState(null);

  // @ts-ignore-next-line: Need to investigate if mock msalConfiguration is enough
  const connection = useMemo(
    () =>
      new EventHubService(
        MEDICON_VILLAGE_CONTEXT_SETTINGS,
        EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
        [],
        msalConfiguration as unknown as IPublicClientApplication,
        null
      ),
    []
  );

  const dispatch = useCommonDispatch();

  useEffect(() => {
    if (connection) {
      connection.connect();

      return () => {
        connection.disconnect();
      };
    }
  }, [connection]);

  const [isLoadingResources, loadResources] = usePromiseCall({
    promise: getResourcesPromise,
    initiallyLoading: true,
    cacheKey: 'ectotable-dashboard-env',
    retryAfter: RETRY_TIMEOUT,
    onSuccess: ([nodeList, gridTypes, enums, signalTypes, equipmentTypes]: [
      NodeResponseModel[],
      string[],
      GetEnumsAndFixedConfigurationsResponseModel,
      SignalTypeResponseModel[],
      EquipmentTypeResponseModel[]
    ]) => {
      const gridTree = createFlatNodeTree(gridTypes as GridType[], nodeList);
      const nodeTree = gridTree.nodeTree;
      const nodeMap = createNodeMap(nodeTree);
      const equipmentMap = createEquipmentMap(nodeTree);
      const signalTypesMap = _.keyBy(signalTypes, 'id');
      const signalTypesNameMap = _.keyBy(signalTypes, 'name');
      const signalUnitTypesMap = _.keyBy(enums.units, 'id');
      const _equipmentTypes = equipmentTypes.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const equipmentTypesMap = _.keyBy(equipmentTypes, 'equipmentTypeId');

      // @ts-ignore-next-line: Set nodes type signature is dynamic unfortunately, need to be fixed
      dispatch(setNodes([nodeList, gridTypes]));
      dispatch(setEnums(enums));
      dispatch(setSignalTypes(signalTypes));
      dispatch(setEquipmentTypes(equipmentTypes));

      setDashboardResourcesValue({
        nodeMap,
        gridTypes,
        isAdmin: false,
        equipmentMap,
        signalTypesMap,
        signalTypesNameMap,
        signalUnitTypesMap,
        equipmentTypes: _equipmentTypes,
        signalProviderTypes: enums.signalProviderTypes,
        equipmentTypesMap,
        isLoadingResources: false
      });
    },
    onError: () => {
      setDashboardResourcesValue({
        hasError: true,
        isLoadingResources: false
      });
    }
  });

  useEffect(() => {
    loadResources();
  }, []);

  useEffect(() => {
    setDataContextValue((oldValue) => ({
      ...oldValue,
      hasError: false,
      isLoadingResources: true
    }));
  }, [isLoadingResources]);

  const [dataContextValue, setDataContextValue] =
    useState<DashboardDataContextType>({
      nodeId: MEDICON_VILLAGE_NODE_ID,
      isLoadingResources: true,
      cacheContext: new Map(),
      equipmentTypesMap: {},
      signalTypesMap: {},
      signalTypesNameMap: {},
      signalUnitTypesMap: {},
      signalProviderTypes: [],
      gridTypes: [],
      isAdmin: false,
      equipmentTypes: [],
      nodeMap: {},
      equipmentMap: {},
      setNode: null,
      hasError: false
    });

  useEffect(() => {
    setDataContextValue((oldValue) => ({
      ...oldValue,
      ...dashboardResourcesValue
    }));
  }, [dashboardResourcesValue]);

  return (
    <Provider store={store}>
      <EventHubServiceContext.Provider value={connection}>
        <TimeRangeContext.Provider value={TimeRangeValue}>
          <DashboardDataContext.Provider value={dataContextValue}>
            <div className="EctocloudEnvironment__container">
              {children}
              <ClientNr />
            </div>
          </DashboardDataContext.Provider>
        </TimeRangeContext.Provider>
      </EventHubServiceContext.Provider>
    </Provider>
  );
};

const EctotableDashboardEnvironmentMemo = React.memo(
  EctotableDashboardEnvironment
);

const EctotableDashboardEnvironmentContainer = ({ children }) => {
  return (
    <Provider store={store}>
      <EctotableDashboardEnvironmentMemo>
        {children}
      </EctotableDashboardEnvironmentMemo>
    </Provider>
  );
};

export default React.memo(EctotableDashboardEnvironmentContainer);
