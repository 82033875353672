import React, { Component } from 'react';
import View from '../../components/View/View';
import Flex from '../../components/Flex/Flex';
import PowerConsumptionView from '../PowerConsumptionView/PowerConsumptionView';
import PowerProductionView from '../PowerProductionView/PowerProductionView';
import EctocloudView from '../EctocloudView/EctocloudView';
import ComboView from '../ComboView/ComboView';
import {
  ROLES,
  roomStorageKey,
  roleStorageKey,
  tokenStorageKey,
  tableStateKey,
  tableSubStateKey
} from '../../components/App/app_util';

import ClientNr from '../../components/App/ClientNr';
import TABLESTATES from '../../config/states';
import defaultLocation from '../../assets/default_location';
import './ClientView.css';
import AppContext, { isConnected } from '../../components/App/app_context';
import SocketMessages from '../../../../server/socket-messages';
import { setAPIFetch } from 'ecto-common/lib/utils/APIFetchInstance';
import createEctotableAPIFetch from '../../utils/createEctotableAPIFetch';
import InfoView from 'js/views/InfoView/InfoView';

type ClientViewProps = {
  socket: any;
  localStoragePrefix: string;
  isMultiScreen: boolean;
  handleClientRoleChange: (role: string) => void;
  role: any;
  size: { width: number; height: number };
};

type ClientViewState = {
  data: any;
  tableSubState: any;
  tableState: any;
  ectocloudMode: boolean;
};

/**
 * View used for all Client screens (acts as a wrapper, selecting the
 * relevant one).
 */
class ClientView extends Component<ClientViewProps, ClientViewState> {
  static contextType = AppContext;
  socketConnections: Record<string, (data: any) => void>;
  timeoutTickInterval: number | undefined;
  socket: any;

  constructor(props) {
    super(props);

    this.onSocketDisconnect = this.onSocketDisconnect.bind(this);
    this.onSocketConnectToTable = this.onSocketConnectToTable.bind(this);
    this.onSocketData = this.onSocketData.bind(this);
    this.onSocketTableState = this.onSocketTableState.bind(this);
    this.onSocketEctocloudMode = this.onSocketEctocloudMode.bind(this);

    this.socketConnections = {
      [SocketMessages.PAIR_CLIENT_TO_TABLE]: this.onSocketConnectToTable,
      [SocketMessages.DATA]: this.onSocketData,
      [SocketMessages.DISCONNECT]: this.onSocketDisconnect,
      [SocketMessages.TABLE_STATE]: this.onSocketTableState,
      [SocketMessages.SET_ECTOCLOUD_MODE]: this.onSocketEctocloudMode
    };

    this.timeoutTickInterval = null;
    let storagePrefix = props.localStoragePrefix ?? '';
    const mainState = localStorage.getItem(storagePrefix + tableStateKey);
    const subState = localStorage.getItem(storagePrefix + tableSubStateKey);

    this.state = {
      data: null,
      tableSubState: subState && mainState ? subState : null,
      tableState: mainState ? TABLESTATES[mainState] : null,
      ectocloudMode: false
    };
  }

  componentDidMount() {
    // Socket might not be ready when mounting
    if (this.props.socket) this.connectSocket();
  }

  componentWillUnmount() {
    this.timeoutTickInterval && clearInterval(this.timeoutTickInterval);
    if (this.props.socket) {
      // Unlisten to all socket connections
      Object.entries(this.socketConnections).forEach((sc) => {
        this.props.socket.off(sc[0], sc[1]);
      });
    }
  }

  componentDidUpdate(prevProps) {
    // If socket was created after we mounted, connect the client view now
    // instead.
    if (this.props.socket && !prevProps.socket) this.connectSocket();
  }

  connectSocket() {
    // Listen to all socket connections
    Object.entries(this.socketConnections).forEach((sc) => {
      this.props.socket.on(sc[0], sc[1]);
    });
  }

  onSocketConnectToTable(data) {
    let storagePrefix = this.props.localStoragePrefix ?? '';
    localStorage.setItem(storagePrefix + roomStorageKey, data.room);
    localStorage.setItem(storagePrefix + roleStorageKey, data.role);
    localStorage.setItem(storagePrefix + tokenStorageKey, data.roomToken);

    if (data.roomToken != null) {
      setAPIFetch(createEctotableAPIFetch(data.roomToken, data.room));
    }

    if (storagePrefix != '') {
      this.props.handleClientRoleChange(data.role);
    } else {
      this.context.reloadApp();
    }
  }

  onSocketDisconnect() {
    console.info('ClientView@disconnect');
    this.setState({ data: null });
  }

  onSocketData(json) {
    try {
      console.assert(!!json, 'No data recieved from server');
      const data = json ? JSON.parse(json) : undefined;

      // console.info(`ClientView@onSocketData - ${ json ? 'Has data' : 'No data' }`);
      if (process.env.DEVELOPMENT && data) {
        // in dev mode we always log the data we get in
        console.info('ClientView@onSocketData -', data);
      }

      if (
        !data.location ||
        !data.location.city ||
        !data.location.longitude ||
        !data.location.latitude
      ) {
        console.warn(
          'ClientView@onSocketData - Location not valid. Using default.',
          data.location
        );
        data.location = defaultLocation;
      }

      this.setState({ data });
    } catch (error) {
      console.error(error);
    }
  }

  onSocketEctocloudMode(ectocloudMode) {
    this.setState({ ectocloudMode });
  }

  onSocketTableState(tableStateObject) {
    const { mainState, subState } = tableStateObject;
    let storagePrefix = this.props.localStoragePrefix ?? '';
    localStorage.setItem(storagePrefix + tableStateKey, mainState);
    localStorage.setItem(storagePrefix + tableSubStateKey, subState);

    console.info(
      `ClientView@onSocketTableState - Changing to "${mainState}" (${subState})`,
      TABLESTATES[mainState]
    );
    this.setState({
      tableState: TABLESTATES[mainState],
      tableSubState: subState
    });
  }

  render() {
    const { role, size, socket } = this.props;
    const { tableState, tableSubState } = this.state;

    if (!isConnected(this.context)) return null;

    if (tableState === TABLESTATES.INFO) {
      return (
        <InfoView socket={socket} role={role} screenContentId={tableSubState} />
      );
    }

    switch (role) {
      default:
        return (
          <View>
            <Flex
              justify="center"
              alignContent="center"
              alignItems="center"
              className="number-wrapper"
            >
              <ClientNr fontSize={size.width * 0.25} />
            </Flex>
          </View>
        );

      case ROLES.combo:
        return (
          <ComboView
            socket={socket}
            data={this.state.data}
            ectocloudMode={this.state.ectocloudMode}
            size={size}
          />
        );
      case ROLES.ectocloud:
        return (
          <EctocloudView
            data={this.state.data}
            ectocloudMode={this.state.ectocloudMode}
            size={size}
          />
        );
      case ROLES.consumption:
        return (
          <PowerConsumptionView
            data={this.state.data}
            ectocloudMode={this.state.ectocloudMode}
            size={size}
          />
        );
      case ROLES.production:
        return (
          <PowerProductionView
            data={this.state.data}
            ectocloudMode={this.state.ectocloudMode}
            size={size}
          />
        );
    }
  }
}

ClientView.contextType = AppContext;

export default ClientView;
