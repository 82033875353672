import STATES from '../../config/states';
import {
  comboViewOrder,
  comboRoleScreenChangeMessage
} from '../../assets/combo_view';

import ImageButton from '../image_button';
import ComboSelectButton from './button_combo_select';
import ComboToggleButton from './button_combo_toggle';
import refreshIcon from '../../assets/refresh.svg';
import settingsIcon from '../../assets/button_drawer/settings.svg';
import ectocloudIcon from '../../assets/button_drawer/ectogrid.svg';
import infoIcon from '../../assets/button_drawer/info.svg';
import EctotableClientAPIGen from 'ecto-common/lib/API/EctotableClientAPIGen';
import MenuDrawer, { MenuDrawerButton } from './menu_drawer';
import ButtonManager from './button_manager';
import TableApp from 'js/Table/TableApp';
import TableP5 from 'js/Table/TableP5';
import { Image } from 'p5';

const defaultButtonSize = 40;

// The position of the button drawer. NOTE that y is from the bottom, not the top
const buttonDrawerPosition = { x: 40, y: 70 };
const buttonDrawerSpacing = 60;

type CreateButtonProps = {
  app: any;
  name: string;
  icon: string;
  menu?: MenuDrawer;
  pressListener: (button: MenuDrawerButton) => void;
  longPressListener?: (button: MenuDrawerButton) => void;
  defaults?: {
    width: number;
    height: number;
  };
  imageCache: Record<string, Image>;
};

function createButton({
  app,
  name,
  icon,
  imageCache,
  menu = null,
  pressListener,
  longPressListener = null,
  defaults = null
}: CreateButtonProps) {
  if (!name) console.error('No name, not rendering drawer button');
  if (!icon) console.error('No icon, not rendering drawer button', name);

  const btn = new ImageButton(icon, null);

  btn.name = name;
  btn.menu = menu;
  btn.setDefault(defaults || { width: 30, height: 30 });

  if (pressListener) btn.addListener(pressListener);
  if (longPressListener) btn.longPress = longPressListener;

  if (app) btn.renderInit(app.p5Instance, imageCache);
  return btn;
}

export default class ButtonDrawer extends ButtonManager {
  isMultiScreen: boolean;
  enableComboButton: boolean;
  state: string;
  visibleComboToggle: boolean;
  currentComboScreen: number;
  comboToggleButton: ComboToggleButton;
  corporationButtons: any;
  comboButtons: any;
  screenContents: any;
  ectocloudButton: ImageButton;
  infoButton: ImageButton;
  settingsButton: ImageButton;
  refreshButton: ImageButton;

  get comboViewSelectionOpen() {
    return !!this.buttons.some((b) =>
      comboViewOrder.find((cvo) => b.name === cvo)
    );
  }

  constructor(
    app: TableApp,
    isMultiScreen: boolean,
    enableComboButton: boolean
  ) {
    super([]);
    this.isMultiScreen = isMultiScreen;
    this.enableComboButton = enableComboButton;
    this.state = app.state;
    this.visibleComboToggle = false;
    this.currentComboScreen = 1;
    this.comboToggleButton = null;
    this.corporationButtons = {};
    this.comboButtons = {};
    this.screenContents = [];

    // We do an optimistic attempt at getting the room configuration for this
    // table, error handling etc is a bit messy since this is not at react
    // component.
    EctotableClientAPIGen.Room.getRoomConfiguration
      .promise({ tenantId: null }, { id: app.room }, null)
      .then((res) => {
        this.screenContents = res.screenContents;
        this.reloadButtons(app);
      })
      .catch((error) => {
        console.error(error);
      });

    this.reloadButtons(app);
  }

  reloadButtons(app: TableApp) {
    if (this.state === STATES.ACTIVE) this.inTableActiveState(app, true);
    else this.inTableStaticState(app, true);
  }

  showComboViewToggle(app: TableApp) {
    this.visibleComboToggle = true;
    this.reloadButtons(app);
  }

  hideComboViewToggle(app: TableApp) {
    this.visibleComboToggle = false;
    if (!this.buttons.find((btn) => btn.name === 'comboToggle')) return;
    this.reloadButtons(app);
    this.comboToggleButton = null;
  }

  getEctocloudModeButton(app: TableApp) {
    if (!this.ectocloudButton) {
      this.ectocloudButton = createButton({
        app,
        name: 'ectocloud',
        icon: ectocloudIcon,
        defaults: {
          width: defaultButtonSize,
          height: defaultButtonSize
        },
        pressListener: () => {
          app.toggleEctocloudMode();
        },
        imageCache: app.imageCache
      });

      this.ectocloudButton.setIsActiveCallback(() => {
        return app.inEctocloudMode;
      });
    }

    return this.ectocloudButton;
  }

  getInfoButton(app: TableApp) {
    if (!this.infoButton) {
      let infoMenuDrawer = new MenuDrawer();
      this.infoButton = createButton({
        app,
        name: 'info',
        icon: infoIcon,
        menu: infoMenuDrawer,
        defaults: {
          width: defaultButtonSize,
          height: defaultButtonSize
        },
        pressListener: () => {
          infoMenuDrawer.toggleVisibility();
        },
        imageCache: app.imageCache
      });

      this.infoButton.setIsActiveCallback(() => {
        return app.state === STATES.INFO || infoMenuDrawer.visible;
      });
    }

    this.infoButton.menu.options = this.screenContents.map(
      (screenContent) =>
        new MenuDrawerButton(screenContent.id, screenContent.name, () => {
          this.infoButton.menu.toggleVisibility();

          if (app.state === STATES.INFO && app.subState === screenContent.id) {
            app.setState(STATES.ACTIVE);
          } else {
            app.setState(STATES.INFO, screenContent.id);
          }
        })
    );

    return this.infoButton;
  }

  isScreenContentFullscreen(contentId: string) {
    return (
      this.screenContents.find((content) => content.id === contentId)
        ?.fullWallScreen != null
    );
  }

  getSettingsButton(app: TableApp) {
    if (!this.settingsButton) {
      this.settingsButton = createButton({
        app,
        name: 'settings',
        icon: settingsIcon,
        defaults: {
          width: defaultButtonSize,
          height: defaultButtonSize
        },
        pressListener: () => {
          app.openSettings();
        },
        imageCache: app.imageCache
      });
    }

    this.settingsButton.setIsActiveCallback(() => {
      return false;
    });

    return this.settingsButton;
  }

  getRefreshButton(app: TableApp) {
    if (!this.refreshButton) {
      this.refreshButton = createButton({
        app,
        name: 'refresh',
        icon: refreshIcon,
        defaults: {
          width: defaultButtonSize,
          height: defaultButtonSize
        },
        pressListener: () => {
          app.initiateFullRefresh();
        },
        imageCache: app.imageCache
      });
    }

    this.refreshButton.setIsActiveCallback(() => {
      return false;
    });

    return this.refreshButton;
  }

  /**
   * Creates one of the combo view related buttons.
   *
   * @param {App} app Current app instance
   * @param {number} [i] Which index was pressed (optional)
   * @param {string} name [name="comboToggle"] Identifier to match icons and text
   */
  getComboButton(app: TableApp, i: number = null, name = 'comboToggle') {
    let btn;

    if (name === 'comboToggle') {
      if (!this.comboButtons[name]) {
        const newComboBtn = new ComboToggleButton(this.currentComboScreen);

        newComboBtn.name = name;
        newComboBtn.addListener(() => {
          if (this.comboViewSelectionOpen) {
            this.removeComboRoleSelection();
            newComboBtn.isOpen = false;
          } else app.socket.emit(...comboRoleScreenChangeMessage());
        });
        newComboBtn.longPress = () => {
          newComboBtn.isOpen = true;
          this.addComboRoleSelection(app);
        };
        newComboBtn.renderInit(app.p5Instance);
        this.comboButtons[name] = newComboBtn;
      }
      this.comboButtons[name].activeScreen = this.currentComboScreen;
      btn = this.comboButtons[name];
    } else {
      if (!this.comboButtons[name]) {
        const newComboSelectBtn = new ComboSelectButton(i + 1);

        newComboSelectBtn.name = name;
        newComboSelectBtn.addListener(() => {
          let newScreen = undefined;

          if (Number.isInteger(i) && i >= 0) newScreen = i + 1;
          app.socket.emit(...comboRoleScreenChangeMessage(newScreen));
        });
        this.comboButtons[name] = newComboSelectBtn;
      }
      btn = this.comboButtons[name];
    }

    return btn;
  }

  getComboRoleSelectionButtons(app: TableApp) {
    return comboViewOrder.map((vo, i) => this.getComboButton(app, i, vo));
  }

  addComboRoleSelection(app: TableApp) {
    if (!this.comboViewSelectionOpen) {
      this.buttons = [
        ...this.buttons,
        ...this.getComboRoleSelectionButtons(app)
      ];
    }
  }

  removeComboRoleSelection() {
    const comboViewSelection = this.comboViewSelectionOpen;

    if (comboViewSelection) {
      this.buttons = this.buttons.filter(
        (b) => !comboViewOrder.find((cvo) => b.name === cvo)
      );
    }
  }

  /**
   * Method to render the "active" state of the button drawer, which
   * correspondsto when buildings are put on the table.
   *
   * @param {App} app Current app instance.
   * @param {boolean} [force=false] Wether to force state or not.
   */
  inTableActiveState(app: TableApp, force = false) {
    return this.inTableStaticState(app, force);
  }

  /**
   * Method to render the "static" state of the button drawer, which
   * correspondsto the any other state than when buildings are put on
   * the table.
   *
   * @param {App} app Current app instance.
   * @param {boolean} [force=false] Wether to force state or not.
   */
  inTableStaticState(app: TableApp, force = false) {
    if (force || this.state !== app.state) {
      this.state = app.state;
      let newButtons = [this.getRefreshButton(app)];

      if (!this.isMultiScreen) {
        newButtons.push(this.getSettingsButton(app));
      }

      if (app.ectomodeButtonEnabled()) {
        newButtons.push(this.getEctocloudModeButton(app));
      }

      if (this.screenContents.length > 0) {
        newButtons.push(this.getInfoButton(app));
      }

      if (this.visibleComboToggle && this.enableComboButton) {
        newButtons.push(this.getComboButton(app));
      }

      this.buttons = newButtons;

      if (
        this.comboButtons.comboToggle &&
        this.comboButtons.comboToggle.isOpen &&
        this.enableComboButton
      ) {
        this.addComboRoleSelection(app);
      }
    }
  }

  draw(p5: TableP5) {
    let xPos = buttonDrawerPosition.x;
    const yPos = p5.renderHeight - buttonDrawerPosition.y;
    // let renderButtons = this.buttons;

    if (this.state !== p5.App.state) {
      this.state = p5.App.state;

      this.reloadButtons(p5.App);
    }

    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];
      button.draw(
        p5,
        {
          x: xPos,
          y: yPos
        },
        this.currentComboScreen
      );

      if (button.menu != null && button.menu.visible) {
        button.menu.draw(
          p5,
          xPos,
          button.bounding.y - button.menu.height() - 10,
          p5.App.subState
        );
      }

      xPos += buttonDrawerSpacing;
    }
  }
}
