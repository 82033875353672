import React, { Component } from 'react';
import Box from '../../components/Box/Box';
import moment from 'moment';
// @ts-ignore-next-line
import * as Vis from '../../vis/vis-graph3d.min';
import * as d3 from 'd3-interpolate';
import layout from '../../styles/variables/layout';
import ScaleHelper from '../../config/scale';
import AppContext from '../../components/App/app_context';

const dataDensity = layout.hourConsumptionDensity; // show every dataDensityth day
const rightNowColor = '#c2c2c2'; // mark right now in the chart with this color

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

/** A larger rotation happening once every n'th second */
const allowBigRotations = true;

/** The constant rotation. Should possibly be turned off for lower performing clients */
const allowSmallRotations = true;

/** This is the framerate we try to maintain in rotations */
const rotationFrameRate = 10;
const horizontalAnimationSpeed = 0.05 / rotationFrameRate;
const verticalAnimationSpeed = 0.0125 / rotationFrameRate;
const distanceAnimationSpeed = 0.0375 / rotationFrameRate;

/** Time between big rotations */
const bigRotationsInterval = 15000;

/** Number of ms we wait after a data update is initiated before we start rotating again */
const animationsPause = 5000;

const cameraPositions = [
  {
    horizontal: 0.54499999,
    vertical: 0.24,
    distance: 2.4
  },
  {
    horizontal: -0.345,
    vertical: 0.105,
    distance: 2.4
  },
  {
    horizontal: 0.1,
    vertical: 0.1,
    distance: 2.1
  }
];

type HourlyChartProps = {
  data: any;
  mainColor: string;
  size: { width: number; height: number };
  animate: boolean;
  title: string;
};

type HourlyChartState = {
  graphInitialized: boolean;
};

class HourlyChart extends Component<HourlyChartProps, HourlyChartState> {
  animationHorizontalDirection: number;
  animationVerticalDirection: number;
  animationDistanceDirection: number;
  canvasRef: any;
  emptyDataSet: any;
  rotating: boolean;
  updating: boolean;
  rotationTimeout: any;
  zMax: number;
  canvasTimeout: any;
  canvasBigRotationInterval: any;
  canvasSmallRotationInterval: any;
  cameraPositionIndex: number;
  zMin: number;
  zAvg: number;
  sumOfGraph: number;
  datesArray: number[][];
  graph: any;
  updateTimer: any;

  constructor(props) {
    super(props);

    // These will keep track of in what direction to rotate the graph
    this.animationHorizontalDirection = 1;
    this.animationVerticalDirection = 1;
    this.animationDistanceDirection = 1;

    this.calculateZValues();
    this.canvasRef = React.createRef();
    this.setCanvas = this.setCanvas.bind(this);

    this.emptyDataSet = new Vis.DataSet();
    this.emptyDataSet.add({
      id: 0,
      x: 0,
      y: 0,
      z: 0
    });

    this.state = { graphInitialized: false };
    this.canvasTimeout = null;
    this.canvasBigRotationInterval = null;
    this.canvasSmallRotationInterval = null;

    this.cameraPositionIndex = 0;

    this.rotating = false;
    this.updating = false;

    /** Keeps track of a timeout where the rotations are paused while receiving updates */
    this.rotationTimeout;
  }

  componentDidMount() {
    this.setCanvas();
  }

  componentWillUnmount() {
    clearTimeout(this.canvasTimeout);
    clearInterval(this.canvasBigRotationInterval);
    clearTimeout(this.canvasSmallRotationInterval);
  }

  /**
   * In order to color the graph correctly we need to first calculate where
   * zMax and zMin are. We try not to run this so often.
   */
  calculateZValues() {
    this.zMax = 0;
    this.zMin = 0;
    this.zAvg = 0;
    this.sumOfGraph = 0;

    if (!this.props.data) return;

    this.datesArray = Object.values(this.props.data);

    for (let dateIndex = 0; dateIndex < this.datesArray.length; dateIndex++) {
      const hours = this.datesArray[dateIndex];

      for (let j = 0; j < 24; j++) {
        this.sumOfGraph += hours[j];

        if (!this.zMax || hours[j] > this.zMax) {
          this.zMax = hours[j];
        }
        if (!this.zMin || hours[j] < this.zMin) {
          this.zMin = hours[j];
        }
      }
    }

    // if zMax becomes too small the color interpolation screws up.
    this.zMax = Math.max(this.zMax, 0.1);
    this.zAvg = this.zMax - (this.zMax - this.zMin) / 2;
  }

  createDataObject() {
    const interpolator = d3.interpolate(
      this.context.colors.black,
      this.props.mainColor
    );

    // Create and populate a data table.
    let data = new Vis.DataSet();

    const currentDayOfYear = moment().dayOfYear();
    const currentHour = moment().hour();

    if (!this.datesArray) {
      return this.emptyDataSet;
    } else {
      let counter = 0;
      let day = 0;
      let today = false;
      let now = false;

      for (let date in this.datesArray) {
        today = currentDayOfYear > day && currentDayOfYear < day + dataDensity;

        for (let hour = 0; hour < this.datesArray[date].length; hour++) {
          now = currentHour === hour;

          const watts = this.datesArray[date][hour];
          let color;

          if (today && now) {
            // we color this point differently if it's right now
            color = rightNowColor;
          } else {
            // otherwise, we interpolate between maincolor and black
            color = interpolator((watts - this.zMin) / (this.zMax - this.zMin));
          }
          data.add({
            id: counter++,
            x: day,
            y: hour,
            z: watts,
            color: color,
            style: this.datesArray[date][hour]
          });
        }
        today = false;

        day += dataDensity;
      }
    }

    return data;
  }

  setCanvas() {
    const data = this.createDataObject();
    const lightGrey = this.context.colors.lightGrey;

    // specify options
    let options = {
      width: '100%',
      height: '100%',
      style: 'surface',
      showPerspective: true,
      showGrid: true,
      showShadow: false,
      keepAspectRatio: false,
      verticalRatio: 0.3,
      gridColor: lightGrey,
      axisColor: lightGrey,
      zMin: 0,
      axisFontSize: ScaleHelper.scaleFromPx(22, this.props.size),
      cameraPosition: cameraPositions[this.cameraPositionIndex],
      xValueLabel: (x) => monthNames[Math.round(x / 31)],
      yValueLabel: (y) => {
        let t = y;

        if (t.length < 2) {
          t = '0' + t;
        }
        return t + ':00';
      },
      yLabel: '',
      xLabel: '',
      zLabel: 'MW',
      zMax: this.zMax
    };

    // set a delay because otherwise the graph starts drawing before
    // the div has it's final size
    this.canvasTimeout = setTimeout(() => {
      if (!this.canvasRef.current) {
        console.error('HourlyChart: No canvas, cannot create Vis');
        return;
      }

      this.graph = new Vis.Graph3d(this.canvasRef.current, data, options);

      if (this.graph) {
        this.setState({ graphInitialized: true });
      }

      if (this.props.animate) {
        if (allowBigRotations) {
          this.canvasBigRotationInterval = setInterval(() => {
            if (this.sumOfGraph > 0) {
              this.bigRotation();
            }
          }, bigRotationsInterval);
        }

        if (allowSmallRotations) {
          this.canvasSmallRotationInterval = setInterval(() => {
            if (this.sumOfGraph > 0) {
              this.smallRotation();
            }
          }, 1000 / rotationFrameRate);
        }
      }
    }, 2000);
  }

  smallRotation() {
    if (this.updating) return;

    const pos = this.graph.getCameraPosition();

    if (pos.horizontal >= 0.7) {
      this.animationHorizontalDirection = -1;
    } else if (pos.horizontal <= -0.9) {
      this.animationHorizontalDirection = 1;
    }

    if (pos.vertical >= 0.33) {
      this.animationVerticalDirection = -1;
    } else if (pos.vertical <= 0) {
      this.animationVerticalDirection = 1;
    }

    if (pos.distance >= 2.5) {
      this.animationDistanceDirection = -1;
    } else if (pos.distance <= 1.5) {
      this.animationDistanceDirection = 1;
    }

    this.graph.setCameraPosition({
      horizontal:
        pos.horizontal +
        horizontalAnimationSpeed * this.animationHorizontalDirection,
      vertical:
        pos.vertical + verticalAnimationSpeed * this.animationVerticalDirection,
      distance:
        pos.distance + distanceAnimationSpeed * this.animationDistanceDirection
    });
  }

  bigRotation() {
    if (this.updating) return;

    // cycle throught the preconfigured positions
    this.cameraPositionIndex =
      (this.cameraPositionIndex + 1) % cameraPositions.length;

    this.animateCameraPositionTo(cameraPositions[this.cameraPositionIndex]);
  }

  animateCameraPositionTo(newPosition) {
    this.rotating = true;

    const oldPosition = this.graph.getCameraPosition();

    const duration = 1000;
    let progress = 0;

    const hStep =
      (newPosition.horizontal - oldPosition.horizontal) / rotationFrameRate;
    const vStep =
      (newPosition.vertical - oldPosition.vertical) / rotationFrameRate;
    const dStep =
      (newPosition.distance - oldPosition.distance) / rotationFrameRate;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;

    let interval = setInterval(() => {
      const currentPosition = this.graph.getCameraPosition();

      this.graph.setCameraPosition({
        horizontal: (currentPosition.horizontal += hStep),
        vertical: (currentPosition.vertical += vStep),
        distance: (currentPosition.distance += dStep)
      });

      progress++;

      if (progress === rotationFrameRate) {
        clearInterval(interval);
        _this.rotating = false;
      }
    }, duration / rotationFrameRate);
  }

  shouldComponentUpdate(nextProps, _nextState) {
    // eslint-disable-line no-unused-vars
    if (nextProps.data && this.context.connectionState) {
      this.updating = true;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this;

      clearTimeout(this.rotationTimeout);
      this.rotationTimeout = setTimeout(() => {
        _this.updating = false;
      }, animationsPause);

      return true;
    }
    return false;
  }

  updateData(data) {
    // only run setOptions if really needed
    if (this.state.graphInitialized && this.graph.zRange.max !== this.zMax) {
      this.graph.setData(this.emptyDataSet); // run it on an empty dataset
      this.graph.setOptions({ zMax: this.zMax });
    }

    // This call takes around 5-15 ms
    this.state.graphInitialized && this.graph.setData(data);
  }

  render() {
    const renderStart = Date.now();

    if (this.state.graphInitialized) {
      // This function is actually not expensive at all
      this.calculateZValues();

      // this call takes around 10-50ms
      const data = this.createDataObject();

      clearTimeout(this.updateTimer);
      // If this has taken a long time, we let the updateData wait a sec, in order to see if this is gonna continue for a while
      if (Date.now() - renderStart > 5) {
        this.updateTimer = setTimeout(() => this.updateData(data), 1);
      } else {
        this.updateData(data);
      }
    }

    return (
      <Box title={this.props.title}>
        <div style={{ height: '100%', width: '100%' }} ref={this.canvasRef} />
      </Box>
    );
  }
}

HourlyChart.contextType = AppContext;

export default HourlyChart;
