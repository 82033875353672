import React, { useMemo, useContext } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Legend
} from 'recharts';
import AppContext from '../../components/App/app_context';
import Box from '../../components/Box/Box';
import ScaleHelper from '../../config/scale';
import { EnergyData } from 'js/Table/data_transmitter';
import { HistoricEnergyNeed, PredictedEnergyNeed } from 'js/Table/energy_grid';
import './EctocloudView.css';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';

type PredictionChartProps = {
  data: EnergyData;
  size: { width: number; height: number };
};

type CombinedNeed = Partial<HistoricEnergyNeed & PredictedEnergyNeed> & {
  time: number;
  formattedTime: string;
  electricityImported?: number;
  predictedElectricityImported?: number;
};

const PredictionChart = ({ data, size }: PredictionChartProps) => {
  const darkModeEnabled = useSyncExternalStore(
    darkModeStore.subscribe,
    darkModeStore.getSnapshot
  );
  const componentMargin = ScaleHelper.scaleFromPx(20, size);
  const componentMarginNarrow = componentMargin * 0.75;
  const gridStrokeDash = ScaleHelper.scaleFromPx(3, size);
  const lineStrokeWidth = ScaleHelper.scaleFromPx(3, size);
  const lineStrokeDash = ScaleHelper.scaleFromPx(5, size);

  const { colors } = useContext(AppContext);

  const graphData = useMemo(() => {
    let ret: CombinedNeed[] = [];
    const historic = data?.totalEnergyHistoric?.slice().map((item) => ({
      ...item,
      electricityImported:
        item.totalElectricityNeeded - item.electricitySupplied
    }));

    const predicted = data?.totalEnergyPredictive?.slice().map((item) => ({
      ...item,
      predictedElectricityImported:
        item.predictedTotalElectricityNeeded - item.predictedElectricitySupplied
    }));

    if (
      historic != null &&
      predicted != null &&
      historic.length > 0 &&
      predicted.length > 0
    ) {
      Object.assign(historic[historic.length - 1], predicted[0]);

      ret = [...historic, ...predicted.slice(1)].map((item) => ({
        ...item,
        formattedTime: item.time.toString().padStart(2, '0') + ':00'
      }));
    }

    return ret;
  }, [data]);

  // let's calculate where the reference areas and lines go
  let nowIndex = undefined;

  if (graphData) {
    // we just decide that right now is in the middle
    nowIndex = Math.floor(graphData.length / 2);
  }

  const xAxisPadding = ScaleHelper.scaleFromPx(20, size);
  const fillColor = darkModeEnabled ? colors.lighterGrey : colors.lightGrey;

  return (
    <Box title="Electricity Chart">
      <ResponsiveContainer width="100%" height="100%" debounce={300}>
        <AreaChart
          data={graphData}
          margin={{
            top: 0,
            right: componentMarginNarrow,
            left: componentMarginNarrow,
            bottom: componentMargin
          }}
        >
          <CartesianGrid
            strokeDasharray={`${gridStrokeDash} ${gridStrokeDash}`}
            vertical={false}
            stroke={colors.lightGrey}
          />
          <YAxis
            axisLine={false}
            width={ScaleHelper.scaleFromPx(75, size)}
            label={{
              value: 'MW',
              angle: -90,
              position: 'insideLeft',
              fill: fillColor,
              fontSize: ScaleHelper.scaleFromPx(18, size)
            }}
            tick={{
              fill: fillColor,
              fontSize: ScaleHelper.scaleFromPx(16, size)
            }}
          />

          <XAxis
            dataKey="formattedTime"
            stroke={fillColor}
            minTickGap={50}
            padding={{ left: xAxisPadding, right: xAxisPadding }}
          />

          <ReferenceLine x={nowIndex} stroke={colors.lightGrey} />

          <Area
            type="monotone"
            dot={false}
            name="Need"
            dataKey="totalElectricityNeeded"
            stroke={colors.white}
            fill={colors.need}
            fillOpacity={1}
            strokeWidth={lineStrokeWidth}
            legendType="circle"
          />

          <Area
            type="stepBefore"
            dot={false}
            dataKey="predictedTotalElectricityNeeded"
            stroke={colors.white}
            fill={colors.needPredicted}
            fillOpacity={1}
            strokeDasharray={`${lineStrokeDash} ${lineStrokeDash}`}
            strokeWidth={lineStrokeWidth}
            legendType="none"
          />

          <Area
            type="monotone"
            dot={false}
            name="Solar"
            dataKey="electricitySupplied"
            stroke={colors.solar}
            fill={colors.solar}
            fillOpacity={0.75}
            strokeWidth={lineStrokeWidth}
            legendType="circle"
          />
          <Area
            type="stepBefore"
            dot={false}
            dataKey="predictedElectricitySupplied"
            stroke={colors.solar}
            fill={colors.solarPredicted}
            fillOpacity={0.75}
            strokeDasharray={`${lineStrokeDash} ${lineStrokeDash}`}
            strokeWidth={lineStrokeWidth}
            legendType="none"
          />
          <Legend verticalAlign="top" iconSize={10} align="right" height={36} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(PredictionChart);
